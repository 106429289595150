import formatter from '@/mixins/formatter'
import { SharedAttachmentService } from '@/services'

export default {

  mixins: [formatter],

  data () {
    return {
      companyLogo: null,
      approverLevels: [
        { text: 'Requester', value: 0 },
        { text: 'Senior Mechanic', value: 1 },
        { text: 'Logistics Manager', value: 2 },
        { text: 'Purchasing', value: 3 },
        { text: 'Logistics Officer', value: 4 }
      ],
      partsFrom: [
        { text: 'Inventory', value: 'inventory' },
        { text: 'Purchase', value: 'purchase' }
      ],
      fundingTypes: [
        { text: 'All', value: 'All' },
        { text: 'Petty Cash', value: 'petty-cash' },
        { text: 'Cash Advance', value: 'cash-advance' }
      ],
      itemStatuses: [
        { text: 'For Completion', value: null },
        { text: 'Closed', value: 'closed' },
        { text: 'Cancelled', value: 'cancelled' }
      ],
      vehicleTypes: [
        { text: 'Company Owned', value: 'owned' },
        { text: 'Rented', value: 'rented' }
      ]
    }
  },

  mounted () {
    this.getCompanyLogo()
  },

  methods: {

    findApprover (level, _key, row) {
      return this.findValueOfIndex(this.approverLevels, (row.status === 'Cancelled' ? 0 : parseInt(level)))
    },

    findPartsFrom (type) {
      return this.findValueOfIndex(this.partsFrom, type)
    },

    findFundingType (val) {
      return this.findValueOfIndex(this.fundingTypes, val)
    },

    findVehicleIs (val) {
      return this.findValueOfIndex(this.vehicleTypes, val)
    },

    findItemStatus (val) {
      return this.findValueOfIndex(this.itemStatuses, val)
    },

    findValueOfIndex (arrays, value) {
      const index = arrays.findIndex(search => search.value === value)
      if (index >= 0) {
        return arrays[index].text
      }
      return null
    },

    checkLastRequest (request) {
      return typeof request !== 'string' ? 'First Request' : this.dateFormatterShort(request)?.toUpperCase()
    },

    marginY: (t = 0, b = 0) => ['', { text: ' ', margin: [0, t, 0, b] }, ''],

    pageBreak: (pageBreak = 'after') => ({ text: ' ', pageBreak: pageBreak }),

    attachmentStack (src, name) {
      return {
        stack: [
          { image: src, fit: [100, 100], width: 100, height: 100 },
          { text: name, fontSize: 10 }
        ]
      }
    },

    async getCompanyLogo () {
      await SharedAttachmentService.retrieveCompanyLogo().then(({ data }) => {
        this.companyLogo = data
      })
    },

    createPDFOptions (options) {
      let items = []
      // let lastOdometer = ''
      // let currentOdometer = ''

      if (options.requestedBy.maintenance_items.length >= 5) {
        items = options.requestedBy.maintenance_items.map(
          item => [
            '',
            { text: item.name, fontSize: 10 },
            { text: `${item.quantity} ${item?.unit_of_measurement || ''}`, alignment: 'center', fontSize: 10 },
            { text: this.findPartsFrom(item.item_from), fontSize: 10 },
            { text: item.last_request, fontSize: 10 }
          ]
        )
      } else if (options.requestedBy.maintenance_items.length > 0 && options.requestedBy.maintenance_items.length < 5) {
        items = options.requestedBy.maintenance_items.map(
          item => [
            '',
            { text: item.name, fontSize: 10 },
            { text: `${item.quantity} ${item?.unit_of_measurement || ''}`, alignment: 'center', fontSize: 10 },
            { text: this.findPartsFrom(item.item_from), alignment: 'center', fontSize: 10 },
            { text: this.checkLastRequest(item.last_request), alignment: 'center', fontSize: 8, margin: [0, 2] }
          ]
        )
      }

      const damageItemMargin = (((options.requestedBy.damaged_item_specifications.split(/\r\n|\r|\n/).length) / 2) * 10) - 5

      const causeItemMargin = (((options.requestedBy.cause_of_damage.split(/\r\n|\r|\n/).length) / 2) * 10) - 5

      const recommendationMargin = (((3 + items.length) + ((options.notedBy.remarks.split(/\r\n|\r|\n/).length) / 2)) * 10) - 10

      return this.$pdfmake.createPdf(
        {
          pageMargins: [40, 40, 40, 40],
          info: {
            title: options.info.title,
            author: options.info.author,
            creator: options.info.creator,
            subject: options.info.subject
          },
          defaultStyle: {
            font: 'Lato'
          },
          content: [
            {
              stack: [
                {
                  table: {
                    headerRows: 1,
                    widths: ['25%', '40%', '11.666%', '11.666%', '11.666%'],
                    body: [
                      // ==== HEADER
                      [
                        {
                          colSpan: 5,
                          stack: [
                            {
                              layout: 'noBorders',
                              table: {
                                headerRows: 1,
                                widths: ['23.39%', '50%', '26.61%'],
                                body: [
                                  [
                                    { rowSpan: 3, image: 'logo', fit: [100, 50] },
                                    { colSpan: 1, text: 'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INC.', fontSize: 10, alignment: 'center' },
                                    { rowSpan: 3, text: `No. ${options.requestedBy.document_number}`, alignment: 'center', margin: [5, 20] }
                                  ],
                                  [
                                    '', { colSpan: 1, text: 'Purok Maligaya, Batal, Santiago City', fontSize: 10, alignment: 'center' }, ''
                                  ],
                                  [
                                    '', { colSpan: 1, text: 'REPAIR FORM', alignment: 'center', fontSize: 15, bold: true }, ''
                                  ]
                                ]
                              }
                            }
                          ]
                        }, '', '', '', ''
                      ],
                      // ==== DEPARTMENT
                      [
                        { text: 'Deparment / Area', fontSize: 10 },
                        { text: options.requestedBy.organization_name, fontSize: 10 },
                        { text: 'Date', fontSize: 10 },
                        { colSpan: 2, text: options.requestedBy.date, fontSize: 10 },
                        ''
                      ],
                      [
                        { text: 'Requester', fontSize: 10 },
                        { text: options.requestedBy.name, fontSize: 10 },
                        { text: 'Plate #', fontSize: 10 },
                        { colSpan: 2, text: options.requestedBy.vehicle_license_plate, fontSize: 10 },
                        ''
                      ],
                      // ==== DAMAGED ITEM
                      [
                        {
                          margin: [0, (damageItemMargin <= 0 ? 10 : damageItemMargin)],
                          stack: [
                            { text: 'Requester', bold: true, alignment: 'center', fontSize: 10 },
                            { text: 'Ano ang sira?', alignment: 'center', fontSize: 10 }
                          ]
                        },
                        { colSpan: 4, text: options.requestedBy.damaged_item_specifications, fontSize: 10 }
                      ],
                      // ==== CAUSE OF DAMAGE
                      [
                        {
                          margin: [0, (causeItemMargin <= 0 ? 10 : causeItemMargin)],
                          stack: [
                            { text: 'Requester', bold: true, alignment: 'center', fontSize: 10 },
                            { text: 'Rason ng pagkasira?', alignment: 'center', fontSize: 10 }
                          ]
                        }, { colSpan: 4, text: options.requestedBy.cause_of_damage, fontSize: 10 }
                      ],
                      // ==== RECOMMENDATIONS
                      [
                        {
                          rowSpan: 3 + items.length, text: 'Chief Mechanic', bold: true, alignment: 'center', margin: [0, (recommendationMargin <= 0 ? 10 : recommendationMargin)], fontSize: 10
                        },
                        { colSpan: 4, text: `Rekomendasyon: ${options.notedBy.remarks}`, fontSize: 10 }, '', '', ''
                      ],
                      // ==== ITEMS
                      [
                        '', { colSpan: 4, text: 'Materials / Spare Parts Needed', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10 }
                      ],
                      [
                        '',
                        { text: 'Item / s', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10, margin: [0, 10] },
                        { text: 'Quantity', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10, margin: [0, 10] },
                        { text: 'Inventory / Purchase', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10, margin: [0, 2] },
                        { text: 'Last Request', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10, margin: [0, 2] }
                      ],
                      ...items,
                      // ==== HISTORY/RECORDS
                      [
                        { rowSpan: 2, text: 'History/Records', bold: true, alignment: 'center', margin: [0, 10, 0, 10], fontSize: 10 },
                        { text: 'Last Odometer', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10 },
                        { colSpan: 3, text: 'Current Odometer', alignment: 'center', fillColor: '#CCCCCC', fontSize: 10 },
                        '',
                        ''
                      ],
                      [
                        '',
                        { text: options.notedBy.last_odometer, alignment: 'center', fontSize: 10 },
                        { colSpan: 3, text: options.notedBy.current_odometer, alignment: 'center', fontSize: 10 },
                        '',
                        ''
                      ],
                      // ==== APPROVERS
                      [
                        {
                          colSpan: 5,
                          stack: [
                            {
                              table: {
                                headerRows: 1,
                                widths: ['33.333%', '33.333%', '33.333%'],
                                body: [
                                  [
                                    {
                                      columns: [
                                        { text: 'Requested By:', fontSize: 10 }
                                      ],
                                      fillColor: '#CCCCCC',
                                      border: [false, false, false, true]
                                    },
                                    {
                                      columns: [
                                        { text: 'Noted By:', fontSize: 10 }
                                      ],
                                      fillColor: '#CCCCCC',
                                      border: [true, false, false, false]
                                    },
                                    {
                                      columns: [
                                        { text: 'Approved By:', fontSize: 10 }
                                      ],
                                      fillColor: '#CCCCCC',
                                      border: [true, false, false, true]
                                    }
                                  ],
                                  [
                                    { text: options.requestedBy.name, alignment: 'center', bold: true, border: [false, false, false, true], fontSize: 10 },
                                    { text: options.notedBy.name, alignment: 'center', bold: true, border: [true, true, true, true], fontSize: 10 },
                                    { text: options.approvedBy.name, alignment: 'center', bold: true, border: [true, false, false, true], fontSize: 10 }
                                  ],
                                  [
                                    { text: 'Requester', alignment: 'center', border: [false, true, true, false], fontSize: 10 },
                                    { text: options.notedBy.position_name, alignment: 'center', border: [false, true, true, false], fontSize: 10 },
                                    { text: options.approvedBy.position_name, alignment: 'center', border: [false, true, false, false], fontSize: 10 }
                                  ],
                                  [
                                    { text: options.requestedBy.time, alignment: 'center', border: [false, true, true, false], fontSize: 10 },
                                    { text: options.notedBy.time, alignment: 'center', border: [false, true, true, false], fontSize: 10 },
                                    { text: options.approvedBy.time, alignment: 'center', border: [false, true, false, false], fontSize: 10 }
                                  ]
                                ]
                              }
                            }
                          ]
                        }, '', '', '', ''
                      ]
                    ]
                  }
                },
                { text: 'This repair request is computer generated, any signature that is appointed are all proven as approved.', fontSize: 10, margin: [0, 10, 0, 0] }
                // {
                //   stack: options.attachments.length > 0 ? [
                //     this.pageBreak(),
                //     {
                //       text: 'Attachments: ', fontSize: 14, bold: true
                //     },
                //     {
                //       stack: options.attachments.map(attachment => ({
                //         stack: [
                //           // { image: attachment.encoded.length > 10 ? attachment.encoded : 'invalidImage', width: 250, height: 250, alignment: 'center', margin: [0, 20, 0, 0] },
                //           { image: attachment.file_hash_name, width: 250, height: 250, alignment: 'center', margin: [0, 20, 0, 0] },
                //           { text: attachment.file_original_name, fontSize: 10, alignment: 'center', margin: [0, 10, 0, 10] }
                //         ]
                //       }))
                //     }
                //   ] : []
                // }
              ]
            }
          ],
          // footer: {
          //   stack: [
          //     { text: 'This repair request is computer generated, any signature that is appointed are all approved.', fontSize: 10, margin: [40, 0, 0, 0] }
          //   ]
          // },
          images: {
            logo: this.companyLogo ? this.companyLogo : `${window.location.origin}/img/invalid-image.png`,
            invalidImage: `${window.location.origin}/img/invalid-image.png`
          },
          styles: {
            header: {
              margin: [40, 0, 0, 0]
            },
            footer: {
              fontSize: 9,
              margin: [40, 0, 0, 0]
            }
          }
        },
        null,
        {
          Lato: {
            normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
            bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
            italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
            bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`
          }
        }
      )
    }
  }
}
